import React, { createContext, useState, useContext } from 'react';

const StoryContext = createContext();

export const useStory = () => useContext(StoryContext);

export const StoryProvider = ({ children }) => {
    const [userId, setUserId] = useState(null);
    const [demoMode, setDemoMode] = useState(true);
    const [imageGenerator, setImageGenerator] = useState(null);
    const [credentialId, setCredentialId] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [profiles, setProfiles] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false);
    const [userToken, setUserToken] = useState(null); 

    const [activeProfileId, setActiveProfileId] = useState(null);

    const [activeStoryId, setActiveStoryId] = useState(null);
    const [bookId, setBookId] = useState(null);
    const [storyPrompt, setStoryPrompt] = useState(null);
    const [storySummary, setStorySummary] = useState(null);
    const [storyComplete, setStoryComplete] = useState(null);
    const [storyTitle, setStoryTitle] = useState(null);
    const [titleSvg, setTitleSvg] = useState(null);
    const [bookLayout, setBookLayout] = useState(null);
    
    const [summaryData, setSummaryData] = useState(null);
    const [titleImage, setStoryTitleImage] = useState(null);
    const [titleImageUrl, setStoryTitleImageUrl] = useState(null);
    const [titleImageKey, setStoryTitleImageKey] = useState(null);
    const [animateImages, setAnimateImages] = useState(false);
    const [generateContent, setGenerateContent] = useState(false);

    const [storyGenre, setStoryGenre] = useState(null);
    const [storyThemes, setStoryThemes] = useState(null);
    const [storySetting, setStorySetting] = useState(null);
    const [storyCharacters, setStoryCharacters] = useState(null);

    const [titleImageGenerated, setTitleImageGenerated] = useState(false);
    const [profilesPageActive, setProfilesPageActive] = useState(false);

    return (
        <StoryContext.Provider 
            value={{ 
                userId,
                setUserId,
                demoMode,
                setDemoMode,
                imageGenerator,
                setImageGenerator,
                credentialId,
                setCredentialId,
                userProfile,
                setUserProfile,
                profiles,
                setProfiles,
                loggedIn,
                setLoggedIn,
                userToken,
                setUserToken,
                
                activeProfileId,
                setActiveProfileId,
                activeStoryId, 
                setActiveStoryId, 
                bookId,
                setBookId,
                storyPrompt, 
                setStoryPrompt,
                storySummary,
                setStorySummary,
                storyComplete,
                setStoryComplete,
                bookLayout,
                setBookLayout,
                storyTitle,
                setStoryTitle,
                titleSvg,
                setTitleSvg,
                summaryData,
                setSummaryData,
                titleImage,
                setStoryTitleImage,
                titleImageUrl,
                setStoryTitleImageUrl,
                titleImageKey,
                setStoryTitleImageKey,
                animateImages,
                setAnimateImages,

                generateContent,
                setGenerateContent,

                storyGenre,
                setStoryGenre,
                storyThemes,
                setStoryThemes,
                storySetting,
                setStorySetting,
                storyCharacters,
                setStoryCharacters,
                titleImageGenerated,
                setTitleImageGenerated,

                profilesPageActive,
                setProfilesPageActive
            }}>
            {children}
        </StoryContext.Provider>
    );
};
