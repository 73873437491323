import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { FaUser, FaEnvelope, FaBirthdayCake } from 'react-icons/fa';

const ProfileCreationPage = () => {
  const [step, setStep] = useState(0);
  const [profile, setProfile] = useState({
    type: '',
    name: '',
    age: '',
  });

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: true,
  });

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleInputChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const renderForm = () => {
    switch (step) {
      case 0:
        return (
          <div>
            <FaUser size={30} />
            <h2>What they are</h2>
            <input
              type="text"
              name="type"
              value={profile.type}
              onChange={handleInputChange}
            />
            <button onClick={handleNextStep}>Next</button>
          </div>
        );
      case 1:
        return (
          <div>
            <FaEnvelope size={30} />
            <h2>Their name</h2>
            <input
              type="text"
              name="name"
              value={profile.name}
              onChange={handleInputChange}
            />
            <button onClick={handleNextStep}>Next</button>
          </div>
        );
      case 2:
        return (
          <div>
            <FaBirthdayCake size={30} />
            <h2>Their age</h2>
            <input
              type="number"
              name="age"
              value={profile.age}
              onChange={handleInputChange}
            />
            <button onClick={handleNextStep}>Finish</button>
          </div>
        );
      default:
        return <h2>Profile creation complete!</h2>;
    }
  };

  return <animated.div style={fade}>{renderForm()}</animated.div>;
};

export default ProfileCreationPage;