import { 
    FaCity, FaTree, FaSchool, FaShip, FaMountain, FaHome, FaGlobeAmericas, FaSpaceShuttle, 
    FaSubway, FaFortAwesome, FaRoad, FaWater, FaStop, FaMagic, FaSearch, FaCampground
} from 'react-icons/fa';

import './icons.css';
  
const storySettingIcons = {
    City: FaCity, // Cityscape
    Woods: FaTree, // Forest trees
    Forests: FaTree, // Forest trees
    Tropical: FaTree, // Added tropical icon for tropical settings
    Island: FaTree, // Added island icon for island settings
    School: FaSchool, // School building
    Sea: FaShip, // Ship on the sea
    Mountains: FaMountain, // Mountain range
    Home: FaHome, // House as a symbol of home
    World: FaGlobeAmericas, // Globe for worldwide or diverse settings
    Space: FaSpaceShuttle, // Space shuttle for outer space
    Caves: FaSubway, // Subway for underground settings
    Underground: FaSubway, // Subway for underground settings
    Castle: FaFortAwesome, // Castle for medieval or fantasy settings
    Road: FaRoad, // Road for journeys or road trips
    Underwater: FaWater, // Water for river or water-based settings
    Mystery: FaSearch, // Magnifying glass for mystery settings
    Mysterious: FaSearch, // Magnifying glass for mysterious settings
    Wonder: FaMagic, // Magic wand for magical settings
    Camping: FaCampground // Added camping icon for camping settings
};

function GetSettingsIcon(setting) {
    // Remove spaces and convert to same case for matching
    console.log("setting: ", setting);
    if (!setting) return null;
    if (setting.length <= 3) return null;
    // const words = setting.replace(/,/g, '').split(' ');
    // const icons = words.map(word => {
    const normalizedKey = Object.keys(storySettingIcons).find(key => setting.toLowerCase() === key.toLowerCase() || key.toLowerCase().includes(setting.toLowerCase()));
    return normalizedKey ? storySettingIcons[normalizedKey] : null;
    // });
    // return icons.filter(icon => icon !== null);
}

const SettingIcons = ({ settings }) => {
    // if (!settings) return <FaStop />; // Return null if no setting is provided
    if (!settings) return null; // Return null if no setting is provided
    //if theme does not include a , then return the icon
    // if (settings.includes(',')) {
    // Split 'themes' into an array, trim spaces, and filter out any empty strings
    const settingsList = settings.replace(/,/g, '').split(' ');

    // Map each theme to its corresponding icon, if it exists
    const icons = settingsList.map((setting, index) => {
        const Icon = GetSettingsIcon(setting); // Get the corresponding icon
        return Icon ? <div className='icon-container'>
                            <div className="icon-svg"><Icon key={index} /></div>
                            <div className="icon-text">{setting}</div>
                        </div> 
                        :
                        <></> //Don't show what we dont have icons for
                        // <div className='icon-container'>
                        //     <div className="icon-svg"><FaStop /></div>
                        //     <div className="icon-text">{setting}</div>
                        // </div>; // Render the icon if it exists
    });

    return icons; // Render all icons in a row
    // } else {
    //     const settingsList = settings.split(' ');
    
    //     // Map each theme to its corresponding icon, if it exists
    //     const icons = settingsList.map((setting, index) => {
    //         const Icon = GetSettingsIcon(setting); // Get the corresponding icon
    //         return Icon ? <Icon key={index} /> : null; // Render the icon if it exists
    //     });
    
    //     return <div>{icons}</div>; // Render all icons in a row
    // } 
};

export default SettingIcons;