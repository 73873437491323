import React, { useEffect, useRef, useState } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import './page-image.css';

const PageImage = ( {pageImageUrl, pageAnimatedImageUrl, animateImages} ) => {
    const containerRef = useRef(null);
    const [maxTranslation, setMaxTranslation] = useState({ x: 0, y: 0 });
    let startX = 0;
    let startY = 0;
    let x = 0;
    let y = 0;

    useEffect(() => {
        const container = containerRef.current;
        const updateBoundaries = () => {
            if (container && container.firstChild) {
                const image = container.firstChild;
                const containerRect = container.getBoundingClientRect();
                const imageRect = image.getBoundingClientRect();
                setMaxTranslation({
                    x: Math.max(0, (imageRect.width - containerRect.width) / 2),
                    y: Math.max(0, (imageRect.height - containerRect.height) / 2),
                });
            }
        };

        // Initialize and update boundaries
        updateBoundaries();
        window.addEventListener('resize', updateBoundaries);
        container.addEventListener('load', updateBoundaries, true); // For image load

        const onMouseDown = (event) => {
            startX = event.clientX - x;
            startY = event.clientY - y;
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', stopPanning);
        };

        const onTouchStart = (event) => {
            const touch = event.touches[0];
            startX = touch.clientX - x;
            startY = touch.clientY - y;
            document.addEventListener('touchmove', onTouchMove);
            document.addEventListener('touchend', stopPanning);
        };

        const onMouseMove = (event) => {
            onMove(event.clientX, event.clientY);
        };

        const onTouchMove = (event) => {
            const touch = event.touches[0];
            onMove(touch.clientX, touch.clientY);
        };

        const onMove = (clientX, clientY) => {
            x = Math.min(maxTranslation.x, Math.max(-maxTranslation.x, clientX - startX));
            y = Math.min(maxTranslation.y, Math.max(-maxTranslation.y, clientY - startY));
            container.style.transform = `translate(${x}px, ${y}px)`;
        };

        const stopPanning = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', stopPanning);
            document.removeEventListener('touchmove', onTouchMove);
            document.removeEventListener('touchend', stopPanning);
        };

        container.addEventListener('mousedown', onMouseDown);
        container.addEventListener('touchstart', onTouchStart);

        return () => {
            window.removeEventListener('resize', updateBoundaries);
            container.removeEventListener('load', updateBoundaries, true);
            container.removeEventListener('mousedown', onMouseDown);
            container.removeEventListener('touchstart', onTouchStart);
        };
    }, [maxTranslation.x, maxTranslation.y]); // Re-run when boundaries change

    return (
        // <div ref={containerRef} className="container" id="imageContainer">
        //     <img src="your-image.jpg" alt="Zoomable" />
        // </div>
        <div ref={containerRef} className="book-page-image" id="book-page-image">
            <img id="book-image" src={pageImageUrl} alt="Zoomable" />
        </div>
    );
};

export default PageImage;


// import React, { useEffect, useRef, useState } from 'react';
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

// import './page-image.css';

// const PageImage = ( {pageImageUrl, pageAnimatedImageUrl, animateImages} ) => {
//     const containerRef = useRef(null);
//     const [maxTranslation, setMaxTranslation] = useState({ x: 0, y: 0 });
//     const [scale, setScale] = useState(1); // New state for tracking scale
//     let startX = 0;
//     let startY = 0;
//     let x = 0;
//     let y = 0;
//     let initialDistance = null; // For pinch-zoom

//     useEffect(() => {
//         const container = containerRef.current;
//         const updateBoundaries = () => {
//             if (container && container.firstChild) {
//                 const image = container.firstChild;
//                 const containerRect = container.getBoundingClientRect();
//                 const imageRect = image.getBoundingClientRect();
//                 setMaxTranslation({
//                     x: Math.max(0, (imageRect.width * scale - containerRect.width) / 2),
//                     y: Math.max(0, (imageRect.height * scale - containerRect.height) / 2),
//                 });
//             }
//         };

//         const onMouseDown = (event) => {
//             startX = event.clientX - x;
//             startY = event.clientY - y;
//             document.addEventListener('mousemove', onMouseMove);
//             document.addEventListener('mouseup', stopPanning);
//         };

//         const onTouchStart = (event) => {
//             if (event.touches.length === 1) {
//                 const touch = event.touches[0];
//                 startX = touch.clientX - x;
//                 startY = touch.clientY - y;
//                 // console.log('panning...: ');
//             } else if (event.touches.length === 2) {
//                 // Calculate the initial distance between two fingers
//                 console.log('zooming...: ');
//                 console.log('event.touches[0]: ', event.touches[0]);
//                 console.log('event.touches[1]: ', event.touches[1]);

//                 initialDistance = getDistance(event.touches[0], event.touches[1]);
//             }
//             document.addEventListener('touchmove', onTouchMove);
//             document.addEventListener('touchend', stopPanning);
//         };

//         const onMouseMove = (event) => {
//             onMove(event.clientX, event.clientY);
//         };

//         const onTouchMove = (event) => {
//             if (event.touches.length === 1) {
//                 const touch = event.touches[0];
//                 onMove(touch.clientX, touch.clientY);
//                 // console.log('pan moving...: ');
//             } else if (event.touches.length === 2) {
//                 // Calculate the new distance between two fingers
//                 const distance = getDistance(event.touches[0], event.touches[1]);
//                 console.log('zoom distance...: ', distance);
//                 if (initialDistance) {
//                     const newScale = scale * (distance / initialDistance);
//                     setScale(newScale); // Set new scaling factor
//                     updateBoundaries();
//                 }
//                 initialDistance = distance; // Update the initial distance for the next move event
//             }
//         };

//         const onMove = (clientX, clientY) => {
//             x = Math.min(maxTranslation.x, Math.max(-maxTranslation.x, clientX - startX));
//             y = Math.min(maxTranslation.y, Math.max(-maxTranslation.y, clientY - startY));
//             container.style.transform = `translate(${x}px, ${y}px) scale(${scale})`;
//         };

//         const stopPanning = () => {
//             document.removeEventListener('mousemove', onMouseMove);
//             document.removeEventListener('mouseup', stopPanning);
//             document.removeEventListener('touchmove', onTouchMove);
//             document.removeEventListener('touchend', stopPanning);
//         };

//         container.addEventListener('mousedown', onMouseDown);
//         container.addEventListener('touchstart', onTouchStart);

//         window.addEventListener('resize', updateBoundaries);
//         updateBoundaries(); // Initialize boundaries

//         return () => {
//             window.removeEventListener('resize', updateBoundaries);
//             container.removeEventListener('mousedown', onMouseDown);
//             container.removeEventListener('touchstart', onTouchStart);
//         };
//     }, [maxTranslation.x, maxTranslation.y, scale]); // Re-run when boundaries or scale change
    

//     // Helper function to calculate distance between two points
//     function getDistance(touch1, touch2) {
//         const dx = touch1.clientX - touch2.clientX;
//         const dy = touch1.clientY - touch2.clientY;
//         return Math.sqrt(dx * dx + dy * dy);
//     }
    
//     return (
//         <div ref={containerRef} className="book-page-image" id="book-page-image">
//             <img id="book-image" src={pageImageUrl} alt="Zoomable" />
//         </div>
//         // <TransformWrapper
//         //     initialScale={1.2}
//         // >
//         //     <TransformComponent>
//         //         <img id="book-image" src={pageImageUrl} alt="Zoomable" />
//         //     </TransformComponent>
//         // </TransformWrapper>
//     );
// };

// export default PageImage;
