import React from 'react';
import { useEffect, useState } from 'react';

import { useStory } from '../context/StoryContext';
// import API from '../utils/api_axios';

import './pricing-page.css'; // Make sure to create this CSS file
// import axios from 'axios';

import { KT_API_URL } from '../utils/api_axios';

// const DEV_UI_URL = 'http://localhost:3001';

// var KT_API_URL = __DEV__ ? DEV_API_URL : PROD_API_URL;

const plans = [
  { id: 1, priceId: "price_1PEmVMFYyBQwzQ15EXY0QBKO", name: 'Single Adventure', price: '$2.99', features: ['1 AI generated adventure'] },
  { id: 2, priceId: "price_1PEmVhFYyBQwzQ15YrQ4oO9S", name: 'Weekly', price: '$9.99/mo', features: ['1 new book every week!', '+ Buy additional discounted books'] },
  { id: 3, priceId: "price_1PEmW4FYyBQwzQ15rajVQoZw", name: 'All-week', price: '$19.99/mo', features: ['7 books, one for each day of the week!', '+2 free extra books for the weekend', '+ Buy additional discounted books'] },
  { id: 4, priceId: "price_1PEmWVFYyBQwzQ15JhCHWf7F", name: 'Everyday Adventurer', price: '$59.99/mo', features: ['A new story every day!', '+ Buy additional discounted books'] },
];

const PricingPage = () => {
    const { userId,
            activeProfileId } = useStory();

    const [message, setMessage] = useState("");
    
    useEffect(() => {
        const navContainer = document.querySelector('.nav-container');
        navContainer.classList.add('small');
        navContainer.classList.remove('transparent');

        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    // async function CheckoutCredit(priceId) {   
    //     alert("CheckoutCredit priceId: " + priceId);     
    //     await API.post('/api/checkout/credit', { userId, priceId }) // Send the profile path in the request body
    //         .then((response) => {
    //             console.log("CheckoutCredit response:", response);
    //         })
    //         .catch((error) => {
    //             console.error('Error checking out for credit:', error);
    //         });
    // }


    // const CheckoutCredit = async (priceId) => {
    //     // const headers = {
    //     //     'Content-Type': 'application/json',
    //     //     'Access-Control-Allow-Origin': '*',
    //     // };

    //     // const response = await API.post('/api/checkout/checkout-item', { userId, priceId }, { headers });
    //     // const response = await API.post('/api/checkout/checkout-item', { userId, priceId });
    //     const response = await fetch(`${KT_API_URL}/api/checkout/checkout-item`, {
    //         method: 'POST',
    //         headers: {
    //           "Content-Type": 'application/json'
    //         },
    //         body: JSON.stringify({ userId, priceId })
    //       })
    //     const body = await response.json()
    //     window.location.href = body.url
    //     // if (response.ok) {
    //     //     // Success!
    //     // } else {
    //     //     // Error!
    //     // }
    // };

    const Checkout = async (checkoutType, priceId) => {
        // const headers = {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        // };

        // const response = await API.post('/api/checkout/checkout-sub', { userId, priceId }, { headers });
        // const response = await API.post('/api/checkout/checkout-sub', { userId, priceId });
        const response = await fetch(`${KT_API_URL}/api/cart/checkout`, {
            method: 'POST',
            headers: {
              "Content-Type": 'application/json'
            },
            body: JSON.stringify({ userId, priceId, checkoutType })
          })
        const body = await response.json()
        window.location.href = body.url
        // if (response.ok) {
        //     // Success!
        // } else {
        //     // Error!
        // }
    };

    const ProductsDisplay = () => (
        <div className="pricing-container">
            {plans.map((plan) => (
                <div key={plan.id} className="card">
                <div className="card-content">
                    <h2>{plan.name}</h2>
                    <p className="price">{plan.price}</p>
                    <ul>
                    {plan.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                    </ul>
                    {/* <button>Choose Plan</button> */}
                    {(plan.id === 1) ? (
                        <button onClick={() => Checkout('token', plan.priceId)}>Buy Token</button>
                    ) : (
                        <button onClick={() => Checkout('sub', plan.priceId)}>Subscribe</button>
                    )}
                        </div>
                </div>
            ))}
        </div>
    );

    const Message = ({ message }) => (
        <section>
        <p>{message}</p>
        </section>
    );
    // <head>
    //     <script
    //         async
    //         src="https://js.stripe.com/v3/pricing-table.js">
    //     </script>
    // </head>
    // return message ? (
    //     <>
    //         <stripe-pricing-table pricing-table-id="prctbl_1PE3tIFYyBQwzQ153BAsOXbg"
    //             // publishable-key="pk_live_51OiCufFYyBQwzQ154q6xbyM8ojZT32fU05W23AzL1jvNOav8mfvBhHS37Da9I12hXE6akZDRp2KasHicThNoR9qI00EqNhu6YY">
    //             publishable-key="pk_test_51OiCufFYyBQwzQ15Bsno4SKByy6E1MCPtJ3ftpnBEAHk8xMgHi3pd0rvFybDZIeglHfkgFI4b5d6pSHAhwh7M11U001vKsbFCN">
    //         </stripe-pricing-table>
    //     </>
    //     // <Message message={message} />
    // ) : (
    //     // <ProductsDisplay />
    //     <>
    //         <stripe-pricing-table pricing-table-id="prctbl_1PE3tIFYyBQwzQ153BAsOXbg"
    //             // publishable-key="pk_live_51OiCufFYyBQwzQ154q6xbyM8ojZT32fU05W23AzL1jvNOav8mfvBhHS37Da9I12hXE6akZDRp2KasHicThNoR9qI00EqNhu6YY">
    //             publishable-key="pk_test_51OiCufFYyBQwzQ15Bsno4SKByy6E1MCPtJ3ftpnBEAHk8xMgHi3pd0rvFybDZIeglHfkgFI4b5d6pSHAhwh7M11U001vKsbFCN">
    //         </stripe-pricing-table>
    //     </>
    // );

    // return (
    //     // <div style={{ zIndex: 1000 }}>
    //     <div className="pricing-container">
    //         {/* <stripe-pricing-table pricing-table-id="prctbl_1PE3tIFYyBQwzQ153BAsOXbg"
    //             // publishable-key="pk_live_51OiCufFYyBQwzQ154q6xbyM8ojZT32fU05W23AzL1jvNOav8mfvBhHS37Da9I12hXE6akZDRp2KasHicThNoR9qI00EqNhu6YY">
    //             publishable-key="pk_test_51OiCufFYyBQwzQ15Bsno4SKByy6E1MCPtJ3ftpnBEAHk8xMgHi3pd0rvFybDZIeglHfkgFI4b5d6pSHAhwh7M11U001vKsbFCN">
    //         </stripe-pricing-table> */}
    //         <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
    //         <stripe-pricing-table pricing-table-id="prctbl_1PEmXjFYyBQwzQ15h9yTCMUm"
    //             publishable-key="pk_test_51OiCufFYyBQwzQ15Bsno4SKByy6E1MCPtJ3ftpnBEAHk8xMgHi3pd0rvFybDZIeglHfkgFI4b5d6pSHAhwh7M11U001vKsbFCN">
    //         </stripe-pricing-table>
    //     </div>
    // );
    return (
        <ProductsDisplay />
    );
};

export default PricingPage;
