import React, { useState, useEffect, useRef } from 'react';
import { useStory } from '../context/StoryContext';
import API from '../utils/api_axios';
import { profileSchema } from './ProfileSchema';

import './AIProfileCreator.css';

function AIProfileCreator() {
  const { userId } = useStory();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [profileData, setProfileData] = useState(profileSchema.profile_data);
  const chatEndRef = useRef(null);

  useEffect(() => {
    startProfileConversation();
  }, []);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const startProfileConversation = async () => {
    const initialMessage = "Hi there! I'm here to help you create a profile for your child. Let's start with your child's name. What's your child's first name?";
    setMessages([{ text: initialMessage, sender: 'ai' }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    setMessages([...messages, { text: input, sender: 'user' }]);
    setInput('');

    try {
      const response = await API.post('/api/profile/chat', { 
        message: input, 
        profileData: profileData 
      });

      setMessages(prevMessages => [...prevMessages, { text: response.data.message, sender: 'ai' }]);
      
      if (response.data.updatedProfile) {
        setProfileData(response.data.updatedProfile);
      }

      if (response.data.profileComplete) {
        saveProfile();
      } else if (response.data.nextQuestion) {
        setMessages(prevMessages => [...prevMessages, { text: response.data.nextQuestion, sender: 'ai' }]);
      }
    } catch (error) {
      console.error('Error in chat:', error);
    }
  };

  const saveProfile = async () => {
    try {
      const response = await API.post('/api/files/create-profile', {
        userId,
        profileInfo: {
          profile_id: null,
          version: 1,
          profile_data: profileData
        }
      });
      console.log("Profile saved:", response.data);
      setMessages(prevMessages => [...prevMessages, { text: "Great! Your profile has been saved successfully.", sender: 'ai' }]);
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  return (
    <div className="ai-profile-creator">
      <div className="chat-container">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            <div className="message-bubble">{message.text}</div>
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>
      <form onSubmit={handleSubmit} className="chat-input-form">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your response here..."
          className="chat-input"
        />
        <button type="submit" className="chat-send-button">Send</button>
      </form>
    </div>
  );
}

export default AIProfileCreator;