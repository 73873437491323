import React, { useState, useEffect } from 'react';
// import '../App.css';
// import axios from 'axios';
import './profile-information.css'; 

import { useStory } from '../context/StoryContext';

import { profileSchema } from './ProfileSchema';
// import { profileSchema } from './ProfileSchema_testData';

import API from '../utils/api_axios';

function ProfileInformationForm({ onUpdate, loadProfileData }) {
  const { userId } = useStory();
  const [profileData, setProfileData] = useState(profileSchema.profile_data);

  useEffect(() => {
    if (loadProfileData) {
      setProfileData(loadProfileData);
    }
  }, [loadProfileData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (onUpdate) {
      onUpdate(profileData);

      const profileInfo = {
        profile_id: null,
        version: 1,
        profile_data: profileData
      };

      //push to db
      API.post('/api/files/create-profile', {userId, profileInfo})
        .then((response) => {
          console.log("add profile response: ", response);
          if (response.data.profile) {
            console.log(response.data.profile);
          }
        })
        .catch((error) => {
          console.error('Error adding profile:', error);
        });
    }
    console.log(profileData); // For demonstration purposes, to see the data
  };

  // Handle input field change
  const handleInputChange = (path, value) => {
    const setNestedState = (object, path, value) => {
      const keys = path.split('.');
      const lastKey = keys.pop();
      const lastObj = keys.reduce((obj, key) => obj[key] = obj[key] || {}, object); 
      lastObj[lastKey] = value;
      return object;
    };
    console.log(path, ':', value);
    setProfileData(prevData => setNestedState({ ...prevData }, path, value));
  };

  const renderSpecialFields = (key, value, path) => {
    if (key == 'profile_image') {
      //we need to do something for the profile image
      return (
        <div className="profile-image-container">
          <img className="profile-image" src={`data:image/png;base64,${value}`}></img>
          <input
            type="file"
            id={path}
            name={path}
            className="neu-form-input"
            value={value}
            placeholder={key.split('_').join(' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            onChange={(e) => handleInputChange(path, e.target.value)}
          />
        </div>
      );
    } else if (key == 'birthdate') {
      //render a calendar picker
      return (
        <input
          type="date"
          id={path}
          name={path}
          className="neu-form-input"
          defaultValue={value}
          placeholder={key.split('_').join(' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
          onChange={(e) => handleInputChange(path, e.target.value)}
        />
      );
    } else if (key == 'gender') {
      //render a dropdown to choose form Boy, Girl, Other, or Prefer not to say
      const gender = ['Boy', 'Girl', 'Other', 'Prefer not to say'];
      return (
        <select
          id={path}
          name={path}
          className="neu-form-input"
          onChange={(e) => handleInputChange(path, e.target.value)}
          required
        >
          <option defaultValue='' selected>Select gender</option>
          {gender.map((gender, index) => (
            <option key={index} defaultValue={gender}>{gender}</option>
          ))}
        </select>
      );
          
    } else if (key == 'language') {
      //render a dropdown of languages
      const languages = ['English', 'Spanish', 'French', 'German', 'Italian', 'Mandarin', 'Japanese', 'Korean', 'Russian', 'Arabic', 'Hindi', 'Portuguese', 'Vietnamese', 'Other'];
      return (
        <select
          id={path}
          name={path}
          className="neu-form-input"
          onChange={(e) => handleInputChange(path, e.target.value)}
          required
        >
          <option value='' selected>Select a language</option>
          {languages.map((language, index) => (
            <option key={index} value={language}>{language}</option>
          ))}
        </select>
      );
    } else if (key == 'ethnicity') {
      //render a dropdown
      const ethnicity = ['White', 'Black', 'Indian', 'Asian', 'Pacific Islander', 'Hispanic or Latino', 'Other'];
      return (
        <select
          id={path}
          name={path}
          className="neu-form-input"
          onChange={(e) => handleInputChange(path, e.target.value)}
          required
        >
          <option value='' selected>Select an ethnicity</option>
          {ethnicity.map((ethnicity, index) => (
            <option key={index} value={ethnicity}>{ethnicity}</option>
          ))}
        </select>
      );

    } else if (key == 'parent') {
      //show one parent and reuse with a + button to add more
    } else if (key == 'pet') {
      //show one pet and reuse with a + button to add more
    } else if (key == 'friends') {
      //show one friend and reuse with a + button to add more
    } else {
      //render a text input
      return (
        <input
          type="text"
          id={path}
          name={path}
          className="neu-form-input"
          value={value}
          placeholder={key.split('_').join(' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
          onChange={(e) => handleInputChange(path, e.target.value)}
        />
      );
    }
  };


  // Recursive function to render form fields
  const renderFormFields = (data, prefix = '') => {
    return Object.entries(data).map(([key, value]) => {
      const path = prefix ? `${prefix}.${key}` : key;

      if (!Array.isArray(value)) { //NOT ARRAY VALUE
        if (typeof value == 'string' && value !== null) {
          // {path}
          

          return (
            <div key={path} className="profile-entry">
              <label className="neu-form-label" name={path+"label"} htmlFor={path}>{key.split('_').join(' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</label>
              {renderSpecialFields(key, value, path)}
            </div>
          );
        } else { //not a string
          return (
            <div key={path} className="profile-subsection-array">
              {renderFormFields(value, path)}
            </div>
          );
        }
      } else { //VALUE IS ARRAY
        if (value.length == 0) {
          return (
            <div key={path} className="profile-entry-array">
              <label className="neu-form-label" htmlFor={path}>{key.split('_').join(' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</label>
              {renderSpecialFields(key, value, path)}
            </div>
          );
        } else {
          return (
            <div key={path} className="profile-subsection-array">
              <div className="neu-subsection-label">{key.split('_').join(' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</div>
              {renderFormFields(value, path)}
            </div>
          );
        }
      }
    });
  };

    // Main render function for top-level categories
    const renderSections = () => {
        return Object.keys(profileData).map((category) => (
            <section key={category} className="profile-category">
                <div className='profile-header-label'>
                    {category.split('_').join(' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </div>
                {renderFormFields(profileData[category], category)}
            </section>
        ));
    };
  
    function handleScroll() {
        console.log("handleScroll");
        const profileContainer = document.getElementById('profile-info-container');
        const scrollPosition = profileContainer.scrollTop;
        const navContainer = document.querySelector('.nav-container');
        const profilesContainer = document.getElementById('profiles-container');
        console.log("scrollPosition:", scrollPosition);

        if (scrollPosition > 0) {
            navContainer.classList.add('small');
            navContainer.classList.add('transparent');
            profilesContainer.style.display = 'none';
        } else {
            navContainer.classList.remove('small');
            navContainer.classList.remove('transparent');
            profilesContainer.style.display = 'flex';
        }
    }

    useEffect(() => {
        const profileContainer = document.getElementById('profile-info-container');
        profileContainer.addEventListener('scroll', handleScroll);
        return () => {
            profileContainer.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <form className="profile-form" onSubmit={handleSubmit}>
            <div className="profile-data">
                    {renderSections()}
            </div>
            <button type="submit" className="neu-form-button">Save Profile</button>
        </form>
    );
}

export default ProfileInformationForm;