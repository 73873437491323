
import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './header.css';

import { useStory } from '../context/StoryContext';
import { useShelf } from '../context/BookShelfContext';

const headerArrow = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>;
const headerHandle = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(90)"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M4 10h16M4 14h16" stroke="#000000" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"></path></g></svg>;
const headerDots = <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M13.5 4A1.5 1.5 0 1 1 12 2.5 1.502 1.502 0 0 1 13.5 4zM12 10.5a1.5 1.5 0 1 0 1.5 1.5 1.502 1.502 0 0 0-1.5-1.5zm0 8a1.5 1.5 0 1 0 1.5 1.5 1.502 1.502 0 0 0-1.5-1.5z"></path><path fill="none" d="M0 0h24v24H0z"></path></g></svg>;

const Header = () => {
  const { loggedIn } = useStory();
  const { landingShelfOn, setLandingShelfOn } = useShelf();

  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const headerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
        setIsHeaderVisible(false);
    }
  };

  useEffect(() => {
      if (isHeaderVisible) {
          document.addEventListener('mousedown', handleClickOutside);
      } else {
          document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, [isHeaderVisible]);

  const hideMenu = (route) => {
    console.log('hideMenu');
    const headerDiv = document.getElementById('App-header');
    if (headerDiv) {
      headerDiv.classList.remove('show');
    }

    setIsHeaderVisible(false);
    
    const navContainer = document.querySelector('.nav-container');
    const profilesContainer = document.querySelector('.profiles-container');
    // navContainer.classList.remove('small');
    // navContainer.classList.remove('transparent');
    setLandingShelfOn(false);
    if (route === "/") {
      setLandingShelfOn(true);
      navContainer.classList.remove('small');
      navContainer.classList.remove('transparent');

      // profilesContainer.style.visibility = 'visible';
      profilesContainer.style.display = 'flex';
    } else if (route === "/login") {
      // profilesContainer.style.visibility = 'hidden';
      profilesContainer.style.display = 'none';
    } else if (route === "/book-shelf") {
    //   setLandingShelfOn(false);
    //   navContainer.classList.add('small');
      // profilesContainer.style.visibility = 'visible';
      profilesContainer.style.display = 'flex';
    } else if (route === "/profile-information") {
      // profilesContainer.style.visibility = 'visible';
      profilesContainer.style.display = 'flex';
    } else if (route === "/account") {
      navContainer.classList.add('small');
      // profilesContainer.style.visibility = 'visible';
      profilesContainer.style.display = 'flex';
    } else if (route === "/pricing") {
      // profilesContainer.style.visibility = 'hidden';
      profilesContainer.style.display = 'none';
      navContainer.classList.add('small');
    }
  }

  return (
    <div
      ref={headerRef}
      className={`App-header ${isHeaderVisible ? 'show' : ''}`}
      id="App-header"
      onClick={() => setIsHeaderVisible(true)}
    >

        <div className={`header-arrow ${isHeaderVisible ? 'hide' : ''}`}>
            {headerDots}
        </div>

      <div 
        className={`visible-header`}
      >

        <Link to="/" className="header-button">
          <div className="login-button" onClick={() => hideMenu("/")}>
            Home
          </div>
        </Link>
        <Link to="/book-shelf" className="header-button">
          <div className="login-button" onClick={() => hideMenu("/book-shelf")}>
            Books
          </div>
        </Link>
        {/* <Link to="/profile-information" className="header-button">
          <div className="login-button" onClick={() => hideMenu("/profile-information")}>
            Profiles
          </div>
        </Link> */}
        <Link to="/create-profile-ai" className="header-button">
          <div className="login-button" onClick={() => hideMenu("/create-profile-ai")}>
            Profiles
          </div>
        </Link>
        <Link to="/pricing" className="header-button">
          <div className="login-button" onClick={() => hideMenu("/pricing")}>
            Pricing
          </div>
        </Link>
        <Link to="/account" className="header-button">
          <div className="login-button" onClick={() => hideMenu("/account")}>
            Account
          </div>
        </Link>

        <Link to="/login" className="header-button">
          <div className="login-button" onClick={() => hideMenu("/login")}>
            {loggedIn ? 'Logout' : 'Login'}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Header;
