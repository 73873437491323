// src/PersonalInformationPage.js
// import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ProfileInformationForm from './ProfileInformationForm'; // Make sure you have this component
// import '../App.css';

import { useStory } from '../context/StoryContext';

import API from '../utils/api_axios';
// axios.defaults.baseURL = `${window.location.protocol}//${window.location.hostname}:3013`;


function ProfileInformationPage(loadProfileData) {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    const [titleImageGenerated, setTitleImageGenerated] = useState(false);
    const [profileData, setProfileData] = useState(null); // Data not yet loaded
    // const [summaryData, setSummaryData] = useState(null); // Data not yet loaded

    const { 
        userId,
        loggedIn,
        activeProfileId,
        storyPrompt, 
        setStoryTitle,
        setStorySummary,
        summaryData,
        setSummaryData,
        titleImage,
        setStoryTitleImage,
        titleImageUrl,
        setStoryTitleImageUrl,
        setProfilesPageActive
    } = useStory();

    useEffect(() => {
        //hide the profile container
        // const profileContainer = document.getElementById('demo-profiles-container');
        // if (profileContainer) {
        //   profileContainer.style.display = 'flex';
        // }

        const profileInfoContainer = document.getElementById('profiles-container');
        if (profileInfoContainer) {
            profileInfoContainer.style.display = 'flex';
        }

        const profileBtns = document.getElementsByClassName('profile-btn');
        
        setProfilesPageActive(true);

        Array.from(profileBtns).forEach(btn => {
            btn.onclick = () => {
                getProfile(btn.id);
            };
        });
        
    }, []);

    useEffect(() => {
        if (loggedIn && activeProfileId) {
            setProfileData(loadProfileData);
        }
    }, [loadProfileData]);

    function getProfile(profileId) {
        console.log("getProfile: ", profileId);

        API.post(`/api/files/get-profile/`, { 
            user_id: userId,
            profile_id: profileId
        })
            .then(response => {
                let profileData = response.data; // Assuming the response contains the profile data
                profileData = profileData.profileData; // The api call send's it back this way
                console.log("profileData: ", profileData);
                // const _profileData = profileData.profileData.data.profile_data;
                const _profileData = profileData.data.profile_data;
                setProfileData(_profileData); // Update the profileData state with the retrieved profile
                const profileHeader = document.getElementById('profile-header');
                if (profileHeader) profileHeader.innerText = `Update ${_profileData.personal_information.first_name}'s profile!`;
            })
            .catch(error => {
                console.error('Error retrieving profile:', error);
            });
    }

    return (
        <div className='profile-info-container' id='profile-info-container'>
            <div className="profile-information">
                <h1 id="profile-header">Create a new profile!</h1>
                <ProfileInformationForm onUpdate={(data) => console.log(data)} loadProfileData={profileData} />
            </div>
        </div>
    );
}

export default ProfileInformationPage;