// src/PersonalInformationPage.js
// import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ProfileInformationForm from './ProfileInformationForm'; // Make sure you have this component
// import '../App.css';

import { useStory } from '../context/StoryContext';

import API from '../utils/api_axios';
// axios.defaults.baseURL = `${window.location.protocol}//${window.location.hostname}:3013`;


function CreateProfilePage(loadProfileData) {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const [titleImageGenerated, setTitleImageGenerated] = useState(false);
  const [profileData, setProfileData] = useState(null); // Data not yet loaded
  // const [summaryData, setSummaryData] = useState(null); // Data not yet loaded

  useEffect(() => {
    //hide the profile container
    // const logo_img = document.getElementById('logo-img');
    // if (logo_img) {
    //   logo_img.classList.add('shrink');
    // }
    
    const profileContainer = document.getElementById('demo-profiles-container');
    if (profileContainer) {
      profileContainer.style.display = 'none';
    }

    const profileInfoContainer = document.getElementById('profiles-container');
    if (profileInfoContainer) {
      profileInfoContainer.style.display = 'none';
    }
  }, []);


  const { 
          loggedIn,
          activeProfileId,
          storyPrompt, 
          setStoryTitle,
          setStorySummary,
          summaryData,
          setSummaryData,
          titleImage,
          setStoryTitleImage,
          titleImageUrl,
          setStoryTitleImageUrl
      } = useStory();

  useEffect(() => {
    if (loggedIn && activeProfileId) {
      setProfileData(loadProfileData);
    }
  }, [loadProfileData]);

  const profileInfoData = {
    //This will come from the db

  };
  

  return (
    <div className="profile-information">
      <h1>Create New Profile</h1>
      <ProfileInformationForm onUpdate={(data) => console.log(data)} loadProfileData={profileData} />
    </div>
  );
}

export default CreateProfilePage;