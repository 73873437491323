import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useStory } from '../context/StoryContext';

import { demo_summary_data, demo_summary_title_image_url } from './DemoStory'
import { GetGenreColor } from '../modules/StoryGenres';
import { GetTitleImage, CreateTitleSVG } from './book/BookUtils';
// import BookUtils from './book/BookUtils';
import BookCover from './book/BookCover';
import ThemeIcons from '../modules/StoryThemeIcons';

import './story-summary-page.css';

import API from '../utils/api_axios';
import { __DEV__ } from '../DEV';

const generator = 'Flux';
// const generator = 'SDXL';
// const generator = 'DallE3';

const def_storyPrompt = "Un-oh, it looks like we are missing the story idea.";

const StoryGenSummaryPage = ( ) => {
    // Context imports
    const { 
            userId,
            loggedIn,
            setImageGenerator,
            activeProfileId,
            storyPrompt,
            setStoryTitle,
            titleSvg,
            setTitleSvg,
            storySummary,
            setStorySummary,
            summaryData,
            setSummaryData,
            titleImageUrl,
            setStoryTitleImageUrl,
            setStoryTitleImageKey,
            animateImages,
            setAnimateImages,
            setGenerateContent,
            storyGenre,
            setStoryGenre,
            storyThemes,
            setStoryThemes,
            setStorySetting,
            setStoryCharacters,
            setTitleImageGenerated,
            titleImageGenerated
        } = useStory();
        
    // Local state
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    const [genError, setGenError] = useState(null); // Data not yet loaded

    // ========================================= Page useEffect =========================================
    useEffect(() => {
        console.log("StorySummaryPage useEffect");
        console.log("activeProfileId:", activeProfileId);
        console.log("loggedIn:", loggedIn);
        console.log("hasBeenCalled:", hasBeenCalled);
        console.log("summaryData:", summaryData);
        console.log("storyPrompt:", storyPrompt);

        console.log("StorySummaryPage useEffect generator:", generator);
        setImageGenerator(generator);

        // Add the event listener to the scroll event
        // window.addEventListener('scroll', handleScroll);

        const profiles = document.getElementsByClassName("profiles-container");
        for (let i = 0; i < profiles.length; i++) {
            profiles[i].style.display = "none";
        }

        const msgContainer = document.getElementById('msg-container');
        if (msgContainer) msgContainer.style.visibility = "hidden";

        // Set the local states to null to clear them for the page
        setSummaryData(null);
        setStoryTitle(null);
        setStorySummary(null);
        setStoryTitleImageUrl(null);

        setStoryGenre(null);
        setStoryThemes(null);
        setStorySetting(null);
        setStoryCharacters(null);

        if (!__DEV__ && !hasBeenCalled && loggedIn && activeProfileId && userId) {
            console.log("storyPrompt:", storyPrompt);
            const prompt = storyPrompt;

            API.post('/api/story/summary', { userId, prompt })
                .then((response) => {
                    if (response.data.authorResponse) {
                        const newSummaryData = response.data.authorResponse;
                        console.log(newSummaryData);
                        setSummaryData(newSummaryData);
                        setStoryTitle(newSummaryData.title);
                        setStorySummary(newSummaryData.summary);

                        setStoryGenre(newSummaryData.genre);
                        setTitleSvg(CreateTitleSVG(newSummaryData.title, newSummaryData.genre));
                        setStoryThemes(newSummaryData.themes);
                        setStorySetting(newSummaryData.setting);
                        setStoryCharacters(newSummaryData.characters);

                        console.error('Getting new title image:');
                        
                        // GetTitleImage(newSummaryData, generator);
                        GetTitleImage(
                            userId,
                            generator,
                            activeProfileId,
                            setStoryTitleImageUrl,
                            setStoryTitleImageKey,
                            titleImageGenerated,
                            setTitleImageGenerated,
                            newSummaryData,
                            generator
                        );
                        // setStoryTitleImageUrl(imageUrl);
                    } else if (response.data.error) {
                        console.log(response.data.error);
                    }
                })
                .catch((error) => {
                    console.error('Error getting story title and summary:', error);
                    console.log(error.response.data.error);
                    setGenError(error.response.data.error);
                    // setSummaryData({ title: error.response.data.error, summary: error.response.data.error })
                    // setStoryTitle(error.response.data.error);
                    // setStorySummary(error.response.data.error);
                });

            setHasBeenCalled(true);
        // } else if (!loggedIn) {
        } else {
            setSummaryData(demo_summary_data);
            setStoryTitle(demo_summary_data.title);
            setTitleImageGenerated(true);
            setStoryTitleImageUrl(demo_summary_title_image_url);
            setStorySummary(demo_summary_data.summary);
            setStoryGenre(demo_summary_data.genre);
            setTitleSvg(CreateTitleSVG(demo_summary_data.title, demo_summary_data.genre));
            setStoryThemes(demo_summary_data.themes);
            setStorySetting(demo_summary_data.setting);
            setStoryCharacters(demo_summary_data.characters);
            
        }
    // }, [hasBeenCalled]);
    }, []);
    // ========================================= Page useEffect =========================================

    // ========================================= Page functions =========================================


    const getNewTitle = () => {
        alert(`You want to regen the title`);
    };

    const getNewTitleImage = () => {
        alert(`You want to regen the title image`);
    };

    const handleAnimateImagesChange = (event) => {
        setAnimateImages(event.target.checked);
    };

    const SetGenerate = (value) => {
        setGenerateContent(value);
    };

    // const colors = ["red", "blue", "green", "purple", "orange"];

    // // Current color index
    // let currentColorIndex = 0;
  
    // // Function to change the fill color
    // function changeFillColor() {
    //   // Get the SVG text element
    //   const textElement = document.getElementById('book-title-svg-obj');

    //   if (!textElement) return null;

    //   //for each text child, change the fill color
    //     const textChildren = textElement.children;
    //     for (let i = 0; i < textChildren.length; i++) {
    //         textChildren[i].setAttribute('fill', colors[currentColorIndex]);
  
    //         // Update the fill color
    //         // textElement.setAttribute('fill', colors[currentColorIndex]);
        
    //         // Move to the next color
    //         currentColorIndex = (currentColorIndex + 1) % colors.length;
        
    //         // Set a timer to change the color again in 3 seconds (3000 milliseconds)
    //     }
    //     setTimeout(changeFillColor, 3000);
    // }

    function handleScroll() {
        console.log("handleScroll");
        const storyContainer = document.querySelector('.story-summary-container');
        const scrollPosition = storyContainer.scrollTop;
        const navContainer = document.querySelector('.nav-container');
        console.log("scrollPosition:", scrollPosition);

        if (scrollPosition > 0) {
            navContainer.classList.add('small');
            navContainer.classList.add('transparent');
        } else {
            navContainer.classList.remove('small');
            navContainer.classList.remove('transparent');
        }
    }

    useEffect(() => {
        const storyContainer = document.querySelector('.story-summary-container');
        storyContainer.addEventListener('scroll', handleScroll);
        return () => {
            storyContainer.removeEventListener('scroll', handleScroll);
        };
    }, []);
    // ========================================= Page functions =========================================


    return (
        <>
            {(storyPrompt || def_storyPrompt) ? (
                <div className='story-summary-container'>
                    
                    <div className='story-data' id='story-data'>
                        <div className="story-idea">
                            <span style={{fontWeight: "bold"}}>The Idea:</span> {(storyPrompt || def_storyPrompt)}
                        </div>
                        <BookCover summaryCover={true}/>
                        <div className="animate-images-container">
                            <input
                                className='animate-images-checkbox'
                                type="checkbox"
                                checked={animateImages}
                                onChange={handleAnimateImagesChange}
                            />
                            Animate Images
                        </div>
                    </div>
                    {summaryData !== null && (
                        <Link className='button-link' to={`/story-gen-book`} onClick={() => SetGenerate(true)}>
                            <button className="neu-send-button">
                                I'm ready for the whole book!
                            </button>
                        </Link>
                    )}
                    {/* <button className="neu-regen-button" onClick={getNewSummary}>
                        Let's Go!
                    </button> */}
                    {/* <div className="bottom-space" style={{height: '3em', width: "100%"}}> </div> */}
                </div>
            ) : (
                <div className='story-summary'></div>
            )}
        </>
            
    );
};

export default StoryGenSummaryPage;