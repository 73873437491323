// const storyGenres = {
//     FairyTale: '#FFC0CB77', // Pink, representing magic and whimsy
//     Adventure: '#FFA50077', // Orange, representing excitement and energy
//     Mystery: '#00008077', // Navy, representing depth and intrigue
//     AnimalsNature: '#00800077', // Green, representing the natural world
//     Fantasy: '#80008077', // Purple, representing imagination and mystery
//     Bedtime: '#0000FF77', // Blue, representing calmness and tranquility
//     NurseryRhymes: '#FFFF007', // Yellow, representing cheerfulness and youth
//     Poem: '#FFD70077', // Gold, representing value and tradition
// };

const storyGenres = {
    FairyTale: {
      fontFamily: '"Texturina", serif',
      fontWeight: 700,
      color: '#DAB6FC77', // Lavender for a whimsical, enchanting feel
      charCount: 18
    },
    Adventure: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 700,
      color: '#FFA50077', // Orange for excitement and movement
      charCount: 17
    },
    Mystery: {
      fontFamily: '"Quicksand", sans-serif',
      fontWeight: 700,
      color: '#4B008277', // Indigo for elegance and suspense
      charCount: 18
    },
    AnimalsNature: {
      fontFamily: '"League Spartan", sans-serif',
      fontWeight: 700,
      color: '#228B2277', // Forest green for a natural, lively vibe
      charCount: 20
    },
    Fantasy: {
      fontFamily: '"Pacifico", cursive',
      fontWeight: 700,
      color: '#9966CC77', // Amethyst for a mystical, ancient feel
      charCount: 20
    },
    Bedtime: {
      fontFamily: '"Neucha", cursive',
      fontWeight: 700,
      color: '#1E90FF77', // Dodger blue for relaxation and comfort
      charCount: 23
    },
    NurseryRhymesPoem: {
      fontFamily: '"Annie Use Your Telescope", cursive',
      fontWeight: 700,
      color: '#FF69B477', // Hot pink for light, joyful simplicity
      charCount: 23
    },
    Friendship: {
      fontFamily: '"Gentium Book Plus", serif',
      fontWeight: 700,
      color: '#F4A46077', // Sandy brown for warmth and community
      charCount: 20
    },
    FamilyAndLove: {
      fontFamily: '"Fredoka", sans-serif',
      fontWeight: 700,
      color: '#FF634777', // Tomato for heartwarming and gentle themes
      charCount: 20
    },
    CourageAndBravery: {
      fontFamily: '"Libre Baskerville", serif',
      fontWeight: 700,
      color: '#8B000077', // Dark red for strength and confidence
      charCount: 16
    },
    KindnessAndCompassion: {
      fontFamily: '"Alegreya Sans", sans-serif',
      fontWeight: 700,
      color: '#FFB6C177', // Light pink for warmth and approachability
      charCount: 22
    },
    Discovery: {
      fontFamily: '"Oleo Script", system-ui',
      fontWeight: 700,
      color: '#20B2AA77', // Light sea green for clarity and curiosity
      charCount: 20
    }
};
  

function GetGenreColor(genre) {
    // Remove spaces and convert to same case for matching
    // console.log("genre: ", genre);
    if (!genre) return "#F0808077";
    const genreKey = genre.replace(/\s+/g, '').trim();
    const normalizedKey = Object.keys(storyGenres).find(key => key.toLowerCase() === genreKey.toLowerCase() || key.toLowerCase().includes(genreKey.toLowerCase()));
    return normalizedKey ? storyGenres[normalizedKey].color : "#F0808077"; // Return null or you could set a default color
}

function GetGenreFontFamily(genre) {
    // Remove spaces and convert to same case for matching
    // console.log("genre: ", genre);
    if (!genre) return null;
    const genreKey = genre.replace(/\s+/g, '').trim();
    const normalizedKey = Object.keys(storyGenres).find(key => key.toLowerCase() === genreKey.toLowerCase() || key.toLowerCase().includes(genreKey.toLowerCase()));
    return normalizedKey ? storyGenres[normalizedKey].fontFamily : null;
}

function GetGenreFontWeight(genre) {
    // Remove spaces and convert to same case for matching
    // console.log("genre: ", genre);
    if (!genre) return null;
    const genreKey = genre.replace(/\s+/g, '').trim();
    const normalizedKey = Object.keys(storyGenres).find(key => key.toLowerCase() === genreKey.toLowerCase() || key.toLowerCase().includes(genreKey.toLowerCase()));
    return normalizedKey ? storyGenres[normalizedKey].fontWeight : null;
}

function GetGenreCharCount(genre) {
    // Remove spaces and convert to same case for matching
    // console.log("genre: ", genre);
    if (!genre) return null;
    const genreKey = genre.replace(/\s+/g, '').trim();
    const normalizedKey = Object.keys(storyGenres).find(key => key.toLowerCase() === genreKey.toLowerCase() || key.toLowerCase().includes(genreKey.toLowerCase()));
    return normalizedKey ? storyGenres[normalizedKey].charCount : null;
}

module.exports = {
    GetGenreColor,
    GetGenreFontFamily,
    GetGenreFontWeight,
    GetGenreCharCount
};