const profileSchema = {
    profile_id: null,
    version: 1.1,
    profile_image: "",
    profile_data: {
        personal_information: {
            first_name: "",
            nickname: "",
            age: "",
            birthdate: "",
            gender: "",
            language: "",
            ethnicity: "",
            hair_color: "",
            eye_color: ""
        },
        storybook_settings: {
            genre_preferences: [],
            themes_of_interest: [],
            character_types: [],
            settings: []
        },
        interests: {
            favorite_colors: [],
            hobbies: [],
            favorite_animals: [],
            favorite_books: [],
            favorite_foods: [],
            fears: []
        },
        personal_traits: {
            personality: [],
            strengths: [],
            challenges: []
        },

        education: {
            grade: "",
            favorite_subjects: [],
            least_favorite_subjects: [],
        },
        family: {
            parents: [
                {
                    relationship: "",
                    name: "",
                    nickname: ""
                },
                {
                    relationship: "",
                    name: "",
                    nickname: ""
                }
            ],
            siblings: [
                {
                    relationship: "",
                    name: "",
                    nickname: ""
                }
            ],
            pets: [
                {
                    type: "",
                    name: "",
                    breed: ""
                }
            ]   
        },
        friends: [
            {
                name: "",
                age: "",
                interests: []
            }
        ]
    }
}

module.exports = {
    profileSchema
  };