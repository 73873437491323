import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import reportWebVitals from './utils/reportWebVitals';

import { StoryProvider } from './context/StoryContext'; 
import { UserProvider } from './context/UserContext';
import { ShelfProvider } from './context/BookShelfContext';
import { StoryPageProvider } from './context/StoryPageContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';

import './index.css';

// 2.14.23 - Need to move to environment variable
const googleOauthClientId = "188490589036-635i4mivv0t71n3f449dr67lmsu04qb3.apps.googleusercontent.com";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode> //calls all functions two times for testing. Remove for production
    <GoogleOAuthProvider clientId={googleOauthClientId}>
        <StoryProvider>
            <UserProvider>
                <ShelfProvider>
                    <StoryPageProvider>
                        <App />
                    </StoryPageProvider>
                </ShelfProvider>
            </UserProvider>
        </StoryProvider>
    </GoogleOAuthProvider>
    // </React.StrictMode> //calls all functions two times for testing. Remove for production
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
