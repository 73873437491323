// src/SkeletonLoader.js
import React from 'react';
import './skeleton-loader.css'; // Make sure to create this CSS file

const SkeletonLoader = ({ width, height, content }) => {
    
  return (
    <div 
        className="skeleton-loader" 
        style={{ width, height }}
    >
        <div
            className="loader-content" 
            dangerouslySetInnerHTML={{ __html: content }}
        ></div>
    </div>
  );
};

export default SkeletonLoader;