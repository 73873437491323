
import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer = () => {
  return (
    <div className="App-footer">
      <div className="footer-text">
        KidsTales AI © 2024<br />
        All content is provided using generative AI. Hallucinations and errors will occur, we are actively working to minimize these errors.
      </div>
      {/* <Link to="/personal-information" className="neu-button">
        Account
      </Link> */}
    </div>
  );
};

export default Footer;
