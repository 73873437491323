import React, { useState, useEffect } from 'react';

import { useStory } from '../../context/StoryContext';
import { useStoryPage } from '../../context/StoryPageContext';

import BookLink from '../../modules/BookLink';
import SkeletonLoader from '../../modules/SkeletonLoader';
import PageImage from './PageImage';

// import getStructuredBook from './getStructuredBook'; // import the Node.js module

import API from '../../utils/api_axios';

import './story-book-page.css';

const navArrowLeft = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="#8b8b8b"></path> </g></svg>;
const navArrowRight = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="#8b8b8b"></path> </g></svg>;

const StoryPage = () => {
    // Context imports
    const { 
        bookId,
        userId,
        animateImages
    } = useStory();

    const {
        currentPage,
        setCurrentPage,
        pageImageUrl,
        setPageImageUrl,
        pageAnimatedImageUrl,
        setPageAnimatedImageUrl,
        pageText,
        setPageText,
        pageNum,
        setPageNum,
        totalPages,
        setTotalPages
    } = useStoryPage();

    // Local state


    // ========================================= Page useEffect =========================================
    // const getCompleteStory = () => {
    useEffect(() => {
        const kidsTalesLogo = document.getElementById('nav-container');
        kidsTalesLogo.classList.add('small');
        kidsTalesLogo.classList.remove('shrink');
        
        // Hide the profiles on the story page
        const profiles = document.getElementsByClassName("profiles-container");
        for (let i = 0; i < profiles.length; i++) {
            profiles[i].style.display = "none";
        }

        if (pageNum) {
            GetPage(pageNum);
        }
    // };
    }, []);
    // ========================================= Page useEffect =========================================


    // ========================================= Page functions =========================================
    function GetPrevPage() {
        const getPageNum = pageNum - 1;
        if (getPageNum > 0) {
            GetPage(getPageNum);
        }
    };

    function GetNextPage() {
        const getPageNum = pageNum + 1;
        if (getPageNum <= totalPages) {
            GetPage(getPageNum);
        }
    };

    function GetPage(getPageNum) {
        // setPageImageUrl("");
        // setPageText("");
        // setPageNum(getPageNum);
        // setCurrentPage(getPageNum);


        API.post('/api/story/get-page', { 
            bookId: bookId,
            userId: userId,
            pageNum: getPageNum,
        }).then((response) => {
            console.log(response);
            if (response.data.pageData) {
                const pageData = response.data.pageData;
                console.log(pageData);
                console.log("NextPage");
                console.log("currentPage:", currentPage);
                console.log("pageNum:", pageNum);
                console.log("totalPages:", totalPages);
                if ((currentPage <= totalPages) || (pageNum <= totalPages)) {
                    if (pageData.page_number === getPageNum) {
                        console.log(pageData.cf_cloud_url || pageData.cloud_url);
                        setPageImageUrl(pageData.cf_cloud_url || pageData.cloud_url);
                        console.log(pageData.cf_cloud_animated_url);
                        setPageAnimatedImageUrl(pageData.cf_cloud_animated_url);
                        console.log(pageData.page_text);
                        setPageText(pageData.page_text);
                        console.log(pageData.page_number);
                        setPageNum(pageData.page_number);
                        setCurrentPage(pageData.page_number)
                        // SetFullscreenImage(pageData.cf_cloud_url);
                    }
                    console.log("currentPage:", currentPage);
                    console.log("pageNum:", pageNum);
                    console.log("totalPages:", totalPages);
                }
            }
        })
        .catch((error) => {
            console.error('Error fetching next page:', error);
        });

        // setCurrentPage(currentPage + 1);
        // }
    };
    // ========================================= Page functions =========================================


    return (
        <div className='book-page' id='book-page'>
            {pageImageUrl && (
                <PageImage pageImageUrl={pageImageUrl} pageAnimatedImageUrl={pageAnimatedImageUrl} animateImages={animateImages} />
                // <div className="book-page-image" id="book-page-image">
                //     <img id="book-image" src={pageImageUrl} alt="Page Image" />
                // </div>
                // <div className="book-page-image" id="book-page-image"></div>
            )}
            <div className='get-link'>
                <BookLink width="40px" height="40px" userId={userId} bookId={bookId}/>
                {/* <Link className='button-link' to={`/story-book-page`} onClick={() => SetGenerate(true)}>
                    <button className="neu-send-button">
                        <FaExpand />
                    </button>
                </Link> */}
            </div>
            {/* <div className='book-page-content'> */}
                
                <div className="book-page-content">
                    {!pageText ? (
                        <div className="book-page-text">
                            <SkeletonLoader width="100%" height="auto" content="Please wait while I work on generating the story.<br><br>Pages will be accessible as they become available.<br><br>Or come back later and check your bookshelf!"/>
                        </div>
                    ) : (
                        <div className="book-page-text filled">
                            {pageText}
                        </div>
                    )}
                </div>
    
                {/* <div className="page-image">
                    {!pageImageUrl ? (
                        <SkeletonLoader width="90vw" height="90vw" />
                    ) : (
                        <div className='image-container'>
                            {(animateImages && pageAnimatedImageUrl !== '') ? (
                                // <img className="image-content" src={pageAnimatedImageUrl} alt="Page Image" />
                                <video className="image-content" autoPlay loop>
                                    <source src={pageAnimatedImageUrl} type="video/mp4" />
                                </video>
                            ) : (
                                <img className="image-content" src={pageImageUrl} alt="Page Image" />
                            )}
                        </div>
                    )}
                </div> */}
    
                
            {/* </div> */}
            <div className="page-nav">
                {/* <div className="nav-arrow" onClick={() => PrevPage()} style={{ transform: 'rotate(180deg)' }}> */}
                {/* <div className="nav-arrow" onClick={() => GetPrevPage(false)}>
                    {navArrowLeft}
                </div>
                <div className="page-num">
                    {!pageNum ? (
                        <SkeletonLoader width="5vw" height="5vw" />
                    ) : (
                        <div className='num-container'>
                            {pageNum} / {totalPages}
                        </div>
                    )}
                </div>
                <div className="nav-arrow" onClick={() => GetNextPage(true)}>
                    {navArrowRight}
                </div> */}
                {pageNum && (
                    <>
                        <div className="nav-arrow" onClick={() => GetPrevPage(false)}>
                            {navArrowLeft}
                        </div>
                        <div className="page-num">
                            <div className='num-container'>
                                {pageNum} / {totalPages}
                            </div>
                        </div>
                        <div className="nav-arrow" onClick={() => GetNextPage(true)}>
                            {navArrowRight}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default StoryPage;


////
// We have the "Get whole story button", use that button to initiate the backend generation of the whole
// story, pass the whole story to the editor and get the breakdown, return the breakdown to the front end, 
// but also start the image generation for everything, return each image and upload them to the cloudflare
// after the button is hit, we are sent to the storybook skeleton page with a activeUser toggle where we wait
// for the story data to come back, for the first page, if the user leaves the page, the story and all it's
// associated data is not passed to the fornt end, the story is returned to the front end if the user stays 
// in the app, if they close the page, the activeUser is closed on the backend and the story is just saved,
// a notification is sent when the story is available to view. Only one story per StoryId can be created