import axios from 'axios';

import { __DEV__ } from '../DEV';
// const __DEV__ = true;

const PROD_API_URL = 'https://kidstales-api-production.up.railway.app';
const DEV_API_URL = 'http://localhost:3013';
const PROD_UI_URL = 'https://kidstales.studio';
const DEV_UI_URL = 'http://localhost:3001';

var KT_API_URL = __DEV__ ? DEV_API_URL : PROD_API_URL;
var KT_UI_URL = __DEV__ ? DEV_UI_URL : PROD_UI_URL;

const API = axios.create({
    // baseURL: `${window.location.protocol}//${window.location.hostname}:3013`,
    // baseURL: `http://127.0.0.1:3013`,
    // baseURL: `https://kidstales-8ac7be95d5e5.herokuapp.com:3013`,
    // baseURL: `https://kidstales-api-production.up.railway.app`,
    baseURL: KT_API_URL,
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": KT_UI_URL,
      "Access-Control-Allow-Credentials": "true",
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,HEAD',
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
    },
  });

export { KT_API_URL, KT_UI_URL, API };

export default API;