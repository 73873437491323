import React, { createContext, useState, useContext } from 'react';

const StoryPageContext = createContext();

export const useStoryPage = () => useContext(StoryPageContext);

export const StoryPageProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(null);
    const [pageImageUrl, setPageImageUrl] = useState(null);
    const [pageAnimatedImageUrl, setPageAnimatedImageUrl] = useState(null);
    const [pageText, setPageText] = useState(null);
    const [pageNum, setPageNum] = useState(0);
    const [totalPages, setTotalPages] = useState(null);

    return (
        <StoryPageContext.Provider 
            value={{ 
                currentPage,
                setCurrentPage,
                pageImageUrl,
                setPageImageUrl,
                pageAnimatedImageUrl,
                setPageAnimatedImageUrl,
                pageText,
                setPageText,
                pageNum,
                setPageNum,
                totalPages,
                setTotalPages
            }}>
            {children}
        </StoryPageContext.Provider>
    );
};
