import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [userId, setUserId] = useState(null);
    const [credentialId, setCredentialId] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [profiles, setProfiles] = useState([]); // Array to store profiles
    const [loggedIn, setLoggedIn] = useState(false);
    const [userToken, setUserToken] = useState(null); 

    return (
        <UserContext.Provider 
            value={{ 
                userId,
                setUserId,
                credentialId,
                setCredentialId,
                userProfile,
                setUserProfile,
                profiles,
                setProfiles,
                loggedIn,
                setLoggedIn,
                userToken,
                setUserToken,
            }}>
            {children}
        </UserContext.Provider>
    );
};
