import React from 'react';

import API from '../../utils/api_axios';

import { useStory } from '../../context/StoryContext';
import { useShelf } from '../../context/BookShelfContext';

import { GetGenreColor } from '../../modules/StoryGenres';

import { GetTitleImage, CreateTitleSVG, GetNewSummary } from './BookUtils';
// import BookUtils from './BookUtils';

import '../bookshelf-page.css';

import ThemeIcons from '../../modules/StoryThemeIcons';

const errorMsg = "The cover image could not be generated. Please try again."; // Cover generation error message

const refreshSVG = <svg viewBox="0 0 24 24" width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 12C21 16.9706 16.9706 21 12 21C9.69494 21 7.59227 20.1334 6 18.7083L3 16M3 12C3 7.02944 7.02944 3 12 3C14.3051 3 16.4077 3.86656 18 5.29168L21 8M3 21V16M3 16H8M21 3V8M21 8H16" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>;
const trashCan = <svg viewBox="0 0 1024 1024" className="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M667.8 362.1H304V830c0 28.2 23 51 51.3 51h312.4c28.4 0 51.4-22.8 51.4-51V362.2h-51.3z" fill="transparent"></path><path d="M750.3 295.2c0-8.9-7.6-16.1-17-16.1H289.9c-9.4 0-17 7.2-17 16.1v50.9c0 8.9 7.6 16.1 17 16.1h443.4c9.4 0 17-7.2 17-16.1v-50.9z" fill="#CCCCCC"></path><path d="M733.3 258.3H626.6V196c0-11.5-9.3-20.8-20.8-20.8H419.1c-11.5 0-20.8 9.3-20.8 20.8v62.3H289.9c-20.8 0-37.7 16.5-37.7 36.8V346c0 18.1 13.5 33.1 31.1 36.2V830c0 39.6 32.3 71.8 72.1 71.8h312.4c39.8 0 72.1-32.2 72.1-71.8V382.2c17.7-3.1 31.1-18.1 31.1-36.2v-50.9c0.1-20.2-16.9-36.8-37.7-36.8z m-293.5-41.5h145.3v41.5H439.8v-41.5z m-146.2 83.1H729.5v41.5H293.6v-41.5z m404.8 530.2c0 16.7-13.7 30.3-30.6 30.3H355.4c-16.9 0-30.6-13.6-30.6-30.3V382.9h373.6v447.2z" fill="#ffffff"></path><path d="M511.6 798.9c11.5 0 20.8-9.3 20.8-20.8V466.8c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0 11.4 9.3 20.7 20.8 20.7zM407.8 798.9c11.5 0 20.8-9.3 20.8-20.8V466.8c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0.1 11.4 9.4 20.7 20.8 20.7zM615.4 799.6c11.5 0 20.8-9.3 20.8-20.8V467.4c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0 11.5 9.3 20.8 20.8 20.8z" fill="#ffffff"></path></g></svg>;

// let summaryData;
// let generator;
// // let refreshSVG;
// let storyThemes;
// let storyGenre;
// let titleSvg;
// let storySummary;


const BookCover = ({summaryCover = true, book = null, bookIndex = null}) => {
    console.log("BookCover summaryCover:", summaryCover);
    console.log("BookCover book:", book);
    console.log("BookCover bookIndex:", bookIndex);

    const {
        userId,
        imageGenerator,
        storyGenre,
        summaryData,
        storySummary,
        titleImageUrl,
        storyThemes,
        titleSvg,
    } = useStory();

    const {
        landingShelfOn,
        books,
        setBooks,
        setBooksVisible,
        setStartBookIndex,
        numVisible
    } = useShelf();

    function DeleteBook(event, bookData, index) {
        // const { 
        //     userId
        // } = useStory();
    
        // const {
        //     books,
        //     setBooks,
        //     setBooksVisible,
        //     setStartBookIndex,
        //     numVisible
        // } = useShelf();

        event.stopPropagation();
        console.log('Deleting book:', bookData.data.title);
        console.log('bookId:', bookData.book_id);
        console.log('book: ', bookData);
        console.log('bookIndex: ', index);

        const bookId = bookData.book_id;
        const bookTitle = bookData.data.title;

        //create a popup asking the user to confirm the deletion
        if (!window.confirm(`Are you sure you want to delete ${bookTitle}?`)) {
            return;
        }

        API.post('/api/story/delete-book', {
            userId: userId,
            bookId: bookId,
            bookData: bookData
        }).then((response) => {
            console.log(response);
            if (response.data.success) {
                console.log('Book deleted:', bookTitle);
                // // Remove the book from the bookshelf
                const newBooks = books.filter(book => book.book_id !== bookId);
                setBooks(newBooks);
                // remove the book from the booksVisible
                // const newVisibleBooks = booksVisible.filter(book => book.book_id !== bookId);
                const startIndex = 0;
                const endIndex = Math.min(newBooks.length, numVisible);
                const newVisibleBooks = newBooks.slice(startIndex, endIndex);

                // setBooks(newBooks);
                setBooksVisible(newVisibleBooks);
                // setIndex(index - 1);
                setStartBookIndex(index - 1);
            }
        })
        .catch((error) => {
            console.error('Error deleting book:', error);
        });
        
        //alert window that the book has been deleted
        alert(`${bookTitle} has been deleted.`);
    }

    const HandleBookClick = (bookId) => {
        // Redirect the user to the book view page
        window.location.href = `/story-book/${userId}/${bookId}`;
    };

    return (
        <>

            {/* <div key={book.book_id}
                className="book-cover"
                onClick={() => handleBookClick(book.book_id)}
                style={{background: `linear-gradient(to top, #0f2027, #203a4300, #2c536400),
                    url(${book.data.title_image_blob || book.data.cf_title_image})
                    no-repeat 50% 50% / cover`}}
            >
                <div className='book-cover-icons'>
                    <div className='cover-icons'><ThemeIcons themes={book.data.theme} /></div> 
                </div>

                <span className="book-genre" style={{background: GetGenreColor(book.data.genre)}}>{book.data.genre || "No Genre"}</span>

                <div className="book-title">
                    {book.data.title_svg ? (
                        <div id={`book-title-svg-${(bookIndex)}`} dangerouslySetInnerHTML={{__html: book.data.title_svg}}></div>
                    ) : (
                        <div id={`book-title-svg-${(bookIndex)}`} dangerouslySetInnerHTML={{__html: CreateTitleSVG(book.data.title, bookIndex)}}></div>
                    )}
                </div>
                
                {(!thisLandingShelf && !landingShelfOn) && (
                    <div className='delete-button' onClick={(event) => DeleteBook(event, book, bookIndex)}>{trashCan}</div>
                )} 
            </div> */}

            {/* <div key={`${book ? book.book_id : ''}`}  */}
            <div key={bookIndex} 
                // className={`${!titleImageUrl ? 'skeleton-loader' : ''} book-cover ${!summaryCover ? 'book-cover-active no-reflection' : ''}`}
                className={`book-cover ${summaryCover ? 'book-cover-active no-reflection summary-cover' : ''}`}
                {...(book ? {onClick: () => HandleBookClick(book.book_id)} : {})}
                // onClick={() => HandleBookClick(book.book_id)}

                // style={{width: "325px", height: "450px", 
                style={{
                    background: `linear-gradient(to top, #0f2027, #203a4300, #2c536400),
                    url(${summaryCover ? (titleImageUrl || "https://via.placeholder.com/300x400") : (book.data.title_image_blob || book.data.cf_title_image)})
                    no-repeat 50% 50% / cover`}}
            >
                {!titleImageUrl && (
                    <div className='msg-container' id="msg-container">
                        <div className='msg-txt' id="msg-txt">{errorMsg}</div>
                        <div className='msg-refresh' id="msg-refresh" onClick={() => GetTitleImage(summaryData, imageGenerator)}>{refreshSVG}</div>
                    </div>
                )}
                <div className='book-cover-icons'>
                    <div className='cover-icons'><ThemeIcons themes={storyThemes} /></div> 
                    {/* <div className='cover-icons'><SettingIcons settings={storySetting} /></div> */}
                </div>

                <span className="book-genre" style={{background: GetGenreColor(summaryCover ? storyGenre : book.data.genre)}}>{summaryCover ? (storyGenre || "I'm still thinking...") : (book.data.genre || "No Genre")}</span>

                <div className="book-title">
                    {!summaryCover ? (
                        <div id={`book-title-svg`} dangerouslySetInnerHTML={{__html: book.data.title_svg || CreateTitleSVG(book.data.title, book.data.genre)}}></div>
                    ) : (
                        <div id={`book-title-svg`} dangerouslySetInnerHTML={{__html: summaryCover ? titleSvg : CreateTitleSVG("Still Waiting...", "Adventure")}}></div>
                    )}
                </div>

                {(!landingShelfOn) && (
                    <div className='delete-button' onClick={(event) => DeleteBook(event, book, bookIndex)}>{trashCan}</div>
                )} 
            </div>
            {!landingShelfOn && !book && (
                summaryData === null ? (
                    <div style={{backdropFilter: "blur(15px)"}} className="story-summary skeleton-loader">
                        {storySummary ? (
                            "I'm still working on the summary..."
                        ) : (
                            "I can't provide a summary until I have a story idea."
                        )}
                    </div>
                ) : (
                    <div style={{backdropFilter: "blur(0px)"}} className="story-summary">
                        {/* {summaryData.summary} */}
                        <div className='summary-content'>
                            {summaryData.summary}
                        </div>
                        <div
                            className="transparent-corner-button"
                            id='refresh-summary-btn'
                            title="Renew Title"
                            onClick={() => GetNewSummary()}
                        >
                            {refreshSVG}
                        </div>
                    </div>
                )
            )}

            
        </>
    );
};

export default BookCover;
