const bookSchema = {
    user_id: null,
    profile_id: null,
    image_generator: "",
    version: 1,
    prompt: "",
    title: "",
    title_svg: "",
    title_image_description: "",
    // title_image_blob: "",
    cf_title_image: "",
    cf_title_key: "",
    summary: "",
    genre: "",
    theme: "",
    setting: "",
    characters: "",
    completeStory: "",
    expandedStory: "",
    bookLayout: [],
    pages: []
}

module.exports = {
    bookSchema
  };