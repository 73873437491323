// src/SkeletonLoader.js
import React from 'react';
import './skeleton-loader.css'; // Make sure to create this CSS file
import './book-link.css'; // Make sure to create this CSS file
import { FaShareAlt } from 'react-icons/fa';

const BookLink = ({ width, height, userId, bookId }) => {
  console.log("BookLink");
  console.log("userId:", userId);
  console.log("bookId:", bookId);

  function GetLink() {
    console.log("GetLink");
    console.log("userId:", userId);
    console.log("bookId:", bookId);
    const baseUrl = `${window.location.protocol}//${window.location.hostname}:3001`;
    window.open(`${baseUrl}/story-book/${userId}/${bookId}`, "_blank");
  };

  return (
    <div className="book-link" onClick={() => GetLink()} style={{ width, height }}><FaShareAlt size={24} /></div>
  );
};

export default BookLink;