const demo_prompts = [ 
    "Dylan's pet dog, Ziggy, discovers a mysterious treasure buried in the backyard.",
    "Dylan and his friends, Tommy and Lucy, embark on an exciting adventure to a magical island filled with talking animals and hidden surprises."
];

const demo_summary_data = {
    title: 'Dylan and DW in the Galactic Pirate Adventure',
    summary: 'Dylan, a young magician with an insatiable curiosity, stumbles upon a secret world of pirate adventure hidden behind an old magical book. Eager to explore this new realm, Dylan must use his magical abilities to outwit legendary pirate foes, solve perplexing puzzles, and uncover long-lost ancient artifacts, all while embracing the thrill of an extraordinary pirate adventure.',
    genre: 'Fairy Tale',
    themes: 'Friendship, Bravery, Magic, Exploration',
    setting: 'A mysterious island filled with hidden treasures and perils',
    characters: 'Dylan the Young Magician, Pirate Captain Redbeard, Mysterious Island Natives'
}

const demo_summary_title_image_url = "https://rttgnck.com/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjQzYWVlMWFjLTU5MGMtNGM5Ny1hMmM4LWMwYjUyYjA4OWIzMyIsImlhdCI6MTcwODU4MTg1NCwiZXhwIjoxNzExMTczODU0fQ.VAYURf1UHX-xiOzTpYtO_SAMCu22UejLYYlhyh7VYBE/out.png"

const demo_book_data = {
    _expandedStory: "",
    _bookLayout: [],
    _bookId: "demo-book-id-1",
}

module.exports = {
    demo_prompts,
    demo_summary_data,
    demo_summary_title_image_url,
    demo_book_data
  };


//1.27.24 - 2230:  
// !!! First full book (gpt3.5-turbo, replciate/open-dalle1.1) that is generated and accessible to unsigned up users.
// http://localhost:3001/story-book/59ef6fcb-b7a7-4053-bd4f-8bbe83245b0b/e266b39a-350b-4a9a-bc54-e138b9cad511


// .FairyTale {
//     font-family: "Texturina", serif;
// }
// .Adventure {
//     font-family: "Open Sans", sans-serif;
// }
// .Mystery {
//     font-family: "Quicksand", sans-serif;
// }
// .AnimalsNature {
//     font-family: "League Spartan", sans-serif;
// }
// .Fantasy {
//     font-family: "Pacifico", cursive;
// }
// .Bedtime {
//     font-family: "Neucha", cursive;
// }
// .NurseryRhymesPoem {
//     font-family: "Annie Use Your Telescope", cursive;
//   }
// .Friendship {
//     font-family: "Gentium Book Plus", serif;
//   }
// .FamilyAndLove {
//     font-family: "Fredoka", sans-serif;
//   }
// .CourageAndBravery {
//     font-family: "Libre Baskerville", serif;
//   }
// .KindnessAndCompassion {
//     font-family: "Alegreya Sans", sans-serif;
//   }
// .Discovery {
//     font-family: "Oleo Script", system-ui;
// }