import React, { useState, useEffect } from 'react';

import { useStory } from '../context/StoryContext';

import API from '../utils/api_axios';

import './account-page.css'; // Make sure to create and import the CSS for styling

const AccountPage = () => {
    const { userId, userProfile, setUserProfile, loggedIn, setLoggedIn } = useStory();

    // Assuming user information is fetched or stored in state
    // const [userInfo, setUserInfo] = useState({ name: 'John Doe', email: 'john@example.com' });
    // const [generationPin, setGenerationPin] = useState('1234'); // Dummy initial PIN
    const [oldPin, setOldPin] = useState('');
    const [newPin, setNewPin] = useState('');
    const [newPin2, setNewPin2] = useState('');

    useEffect(() => {
        if (userProfile) {
            console.log('loginPage loggedIn:', loggedIn);
            console.log('loginPage userProfile:', userProfile);
        }

        const profilesContainer = document.getElementById('profiles-container');
        if (profilesContainer) {
            profilesContainer.style.display = 'none';
        }

        const demoProfilesContainer = document.getElementById('demo-profiles-container');
        if (demoProfilesContainer) {
            demoProfilesContainer.style.display = 'none';
        }

        // Get user info if they are already logged in
        const userToken = localStorage.getItem('userToken');
        setLoggedIn(localStorage.getItem('loggedIn'));

        if (loggedIn && userToken) {
            API.post(`/api/user/get-user`, {
                userToken: userToken
            })
                .then((response) => {
                    const profileData = response.data.profileData;
                    console.log("profileData.name: " + profileData.name);
                    console.log("profileData.picture: " + profileData.picture);
                    console.log("profileData.email: " + profileData.email);
                    console.log("profileData.user_id: " + profileData.user_id);
                    setUserProfile(profileData);
                    setLoggedIn(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    function showPinSuccess(msg) {
        const pinMsg = document.getElementById('pin-msg');
        pinMsg.textContent = msg;
        pinMsg.style.display = 'block';

        setTimeout(() => {
            pinMsg.textContent = '';
            pinMsg.style.display = 'none';
        }, 5000);
    }

    const updateGenPin = () => {
        if (newPin !== newPin2) {
            console.log('New PINs do not match!');
            showPinSuccess('New PINs do not match!');
            setNewPin('');
            setNewPin2('');
            return;
        }

        // Assuming you have an API endpoint to update the PIN in the database
        API.post(`/api/user/update-pin`, {
            user_id: userId,
            old_pin: oldPin,
            new_pin: newPin
        })
            .then((response) => {
                console.log('updatePinResp: ', response);
                console.log('PIN updated successfully!');
                setOldPin('');
                setNewPin('');
                setNewPin2('');
                showPinSuccess('Generation PIN changed successfully!');
            })
            .catch((error) => {
                console.log('Error updating PIN:', error);
            });
    };

    const deleteAccount = () => {
        const confirmDelete = window.confirm("Are you sure you want to delete your account? This action is permanent and will take a couple of days to process.");

        if (confirmDelete) {
            // Assuming you have an API endpoint to delete the account
            API.post(`/api/user/delete-account`, {
                user_id: userId
            })
                .then((response) => {
                    console.log('deleteAccountResp: ', response);
                    console.log('Account deleted successfully!');
                    // Perform any necessary cleanup or redirection here
                })
                .catch((error) => {
                    console.log('Error deleting account:', error);
                });
        }
    };

    return (
        <div className="account-container">
            <h2>Account Settings</h2>
            <div className="account-plate">
                {userProfile ? (
                    <div className="user-info">
                        <h3>{userProfile.name}</h3>
                        <div  className='profile-picture' >
                                <img src={userProfile.picture !== null ? userProfile.picture : ""} alt="user image" />
                        </div>
                        {/* <p><strong>Email:</strong> {userProfile.email}</p> */}
                    </div>
                ) : (
                    <div className="user-info">
                        <h3>Loading...</h3>
                        {/* <p><strong>Email:</strong>Please wait...</p> */}
                    </div>
                )}
                {/* <div onSubmit={() => updateGenPin()} className="pin-change-form"> */}
                <div className="pin-change-form">
                    <h3>Change Generation PIN</h3>
                    <input
                        className='neu-form-input'
                        type="password"
                        placeholder="Old PIN"
                        value={oldPin}
                        onChange={(e) => setOldPin(e.target.value)}
                        required
                    />
                    <input
                        className='neu-form-input'
                        type="password"
                        placeholder="New PIN"
                        value={newPin}
                        onChange={(e) => setNewPin(e.target.value)}
                        required
                    />
                    <input
                        className='neu-form-input'
                        type="password"
                        placeholder="Repeat New PIN"
                        value={newPin2}
                        onChange={(e) => setNewPin2(e.target.value)}
                        required
                    />
                    <div className='pin-msg' id='pin-msg' style={{display: "none"}}></div>
                    {/* <button className='neu-form-button' id='pin-btn' type="submit" >Update PIN</button> */}
                    <button className='neu-form-button' id='pin-btn' onClick={() => updateGenPin()} >Update PIN</button>
                    <hr/>
                    <button className='neu-form-button' id='delete-btn' onClick={() => deleteAccount()} >Delete Account</button>
                </div>
            </div>
        </div>
    );
};

export default AccountPage;
