import React from 'react';

import { 
    FaUsers, FaHome, FaBinoculars, FaCompass, FaLeaf, FaUserSecret, FaBookOpen, FaHandsHelping,
    FaMountain, FaSmile, FaShieldAlt, FaMagic, FaStar, FaStop 
} from 'react-icons/fa';

const storyThemeIcons = {
    Friendship: FaUsers, // Heart as a symbol of love and friendship
    FamilyAndLove: FaHome, // Home as a symbol of family and love
    CourageAndBravery: FaShieldAlt, // Shield as a symbol of courage and protection
    KindnessAndCompassion: FaHandsHelping, // Helping hands as a symbol of kindness and compassion
    AdventureAndExploration: FaCompass, // Updated to FaCompass for adventure and exploration
    Discovery: FaBinoculars, // Binoculars as a symbol of discovery
    NatureAndEnvironment: FaLeaf, // Leaf as a symbol of nature and environment
    IdentityAndSelfExpression: FaUserSecret, // Mysterious figure as a symbol of identity and self-expression
    LearningAndCuriosity: FaBookOpen, // Open book as a symbol of learning and curiosity
    SharingAndCooperation: FaHandsHelping, // Reusing helping hands for sharing and cooperation
    OvercomingChallenges: FaMountain, // Mountain as a symbol of challenges to be overcome
    EmotionsOrFeelings: FaSmile, // Smiling face as a symbol of emotions and feelings
    HonestyAndIntegrity: FaStar, // Star as a symbol of honesty and integrity
    MagicAndWonder: FaMagic // Magic wand as a symbol of magic and wonder
};

function GetThemeIcon(theme) {
    // Remove spaces and convert to same case for matching
    console.log("theme: ", theme);
    if (!theme) return FaStop;
    // const words = theme.split(' ');
    //rejoin and capitalize
    // const themeKey = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
    // const themeKey = theme.replace(/\s+/g, '').trim();
    // const normalizedKey = Object.keys(storyThemeIcons).find(key => key.toLowerCase() === themeKey.toLowerCase());
    // const normalizedKey = Object.keys(storyThemeIcons).find(key => themeKey.toLowerCase() === key.toLowerCase() || key.toLowerCase().includes(themeKey.toLowerCase()));
    // return normalizedKey ? storyThemeIcons[normalizedKey] : FaStop; // Return null or you could set a default color

    const normalizedKey = Object.keys(storyThemeIcons).find(key => theme.toLowerCase() === key.toLowerCase() || key.toLowerCase().includes(theme.toLowerCase()));
    return normalizedKey ? storyThemeIcons[normalizedKey] : null;
}

const ThemeIcons = ({ themes }) => {
    // if (!themes) return <FaStop />; // Return null if no themes are provided
    if (!themes) return null; // Return null if no themes are provided
    //if theme does not include a , then return the icon
    if (themes.includes(',')) {
        // Split 'themes' into an array, trim spaces, and filter out any empty strings
        // const themeList = themes.split(',').map(theme => theme.trim()).filter(theme => theme);
        const themeList = themes.replace(/,/g, '').split(' ');

    
        // Map each theme to its corresponding icon, if it exists
        const icons = themeList.map((theme, index) => {
            if (theme.toLowerCase() === 'and') return null;
            const Icon = GetThemeIcon(theme); // Get the corresponding icon
            return Icon ? <div className='icon-container'>
                                <div className="icon-svg"><Icon key={index} /></div>
                                <div className="icon-text">{theme}</div>
                            </div> 
                            :
                            <></> //Don't show what we dont have icons for
                            // <div className='icon-container'>
                            //     <div className="icon-svg"><FaStop /></div>
                            //     <div className="icon-text">{theme}</div>
                            // </div>; // Render the icon if it exists
        });
        
    
        return icons; // Render all icons in a row
    } else {
        const Icon = GetThemeIcon(themes);
        return Icon ? <Icon /> : null;
    } 
};

export default ThemeIcons;

// module.exports = {
//     ThemeIcon
// };